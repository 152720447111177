<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-2"
                            dark
                            color="primary"
                            @click="createUser"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('admin.users.add') }}
                        </v-btn>
                        <div>
                            <!--                            <v-btn
                                class="ma-2"
                                color="danger"
                            >
                                <v-icon>mdi-cog</v-icon>
                            </v-btn>-->
                            <v-btn
                                outlined
                                class="ma-2"
                                @click="importExcel"
                            >
                                {{ $t('common.importExcel') }}
                            </v-btn>
                            <v-btn
                                outlined
                                class="ma-2"
                                @click="exportTo"
                            >
                                {{ $t('common.export') }}
                            </v-btn>
                        </div>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ $t('admin.users.title') }}
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />

                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <user-create
                    v-if="dialog"
                    :dialog="dialog"
                    :item="itemSelected"
                    @update:dialog="dialog = $event"
                    @update:table="fetchUsers"
                />

                <!--                  @update:dialog="dialogPermissions = $event"-->
                <permissions-dialog
                    v-if="dialogPermissions"
                    :dialog="dialogPermissions"
                    :user="itemSelected"
                    @update:dialog="dialogPermissions = $event"
                />

                <!-- :pagination.sync="pagination" -->
                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ showFirstLastPage: true,
                                     'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :server-items-length="pagination.totalItems"
                    :sort-by="sortby"
                    :multi-sort="true"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.attributes.username="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.attributes.name="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.attributes.lastname="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.attributes.email="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <!--                  -->
                    <template v-slot:item.attributes.created-at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>
                    <template #item.attributes.updated-at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>

                    <template #item.attributes.enable="{ value }">
                        <v-icon
                            small
                        >
                            {{ (value === 1) ? 'mdi-check' : "mdi-close" }}
                        </v-icon>
                        <!--                        <v-icon
                            v-if="value != 1"
                            small
                        >
                            mdi-close
                        </v-icon>-->
                    </template>

                    <template #item.relationships.roles.data="{ value }">
                        {{ getRoles.filter( r => value.find( v => v.id == r.id) ).map(r => r.attributes.display_name).join(',') }}
                    </template>

                    <!--                    <template v-slot:item.role_id="{ item }">
                        {{ itemsRoles.find((r) => r.id_role == item.role_id).name }}
                    </template>-->

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click="editItem(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    v-bind="attrs"
                                    @click="resetPasswordItem(item)"
                                    v-on="on"
                                >
                                    mdi-lock-reset
                                </v-icon>
                            </template>

                            <span>{{ $t('admin.users.passwordReset') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    v-bind="attrs"
                                    @click="deleteItem(item)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    v-bind="attrs"
                                    @click="showDialogPermissions(item)"
                                    v-on="on"
                                >
                                    mdi-lock-open
                                </v-icon>
                            </template>

                            <span>{{ $t('common.permissions') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    @click="showDialogGroups(item)"
                                    v-on="on"
                                >
                                    mdi-account-group
                                </v-icon>
                            </template>

                            <span>{{ $t('common.groups') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchUsers"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <confirm-dialog
                ref="confirm"
            />

            <input
                ref="file"
                type="file"
                hidden
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                @change="seletedFileToImport"
            >

            <changePasswordDialog ref="changePass" />

            <groupsTreeDialogSelect
                :dialog="dialogGroups"
                :items-selected="groupsSelected"
                @update:itemsSelected="changeGroupsSelected"
                @update:dialog="dialogGroups = $event"
            >
                <template v-slot:title>
                    {{ $t('admin.users.groupsDialogTitle') }}
                </template>
                <template
                    v-if="itemSelected && itemSelected.attributes"
                    v-slot:subtitle
                >
                    {{ itemSelected.attributes.username }} {{ ' { ' + itemSelected.attributes.email + ' }' }}
                </template>

                <template
                    v-slot:cleanFilters
                >
                    {{ $t('admin.users.groupsDialogCleanFilter') }}
                </template>
            </groupsTreeDialogSelect>
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'
import UserCreate from '@/components/admin/users/userCreate'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import { debounce } from "debounce";
import store from '../../../store'
import PermissionsDialog from '@/components/admin/users/permissionsDialog'

import groupsTreeDialogSelect from '@/components/groups/groupsTreeDialogSelect'
import changePasswordDialog from "@/components/admin/users/changePasswordDialog";


// import * as dateHelper from '@/helpers/date'

export default {
    components: { PermissionsDialog, UserCreate, ConfirmDialog, groupsTreeDialogSelect, changePasswordDialog },
    data: function () {
        return {
            urlMain: '/users',
            dialog: false,
            dialogPermissions: false,
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: this.$t('admin.users.username'),
                    value: 'attributes.username'
                },
                {
                    text: this.$t('admin.users.firstname'),
                    value: 'attributes.name'
                },
                {
                    text: this.$t('admin.users.lastname'),
                    value: 'attributes.lastname'
                },
                {
                    text: this.$t('admin.users.email'),
                    value: 'attributes.email'
                },
                {
                    text: this.$t('admin.users.enable'),
                    value: 'attributes.enable',
                    sortable: false
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'attributes.created-at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'attributes.updated-at'
                },
                {
                    text: this.$t('admin.users.roles'),
                    value: 'relationships.roles.data'
                },
                // { text: "Role", value: "role_id" },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            search: '',
            items: [],
            itemsRoles: [],
            itemSelected: null,
            sortby: ['id'],
            pagination: {
                totalItems: 10
            },
            options: {
                itemsPerPage: 10
            },
            isValid: true,
            serverCheck: false,

            // for manage the groups
            groups: [], // all groups
            groupsSelected: [],
            dialogGroups: false
        }
    },
    watch: {
        options: {
            handler(newValue, oldValue) {
                this.fetchUsers(newValue)

                if (newValue.sortBy && newValue.sortBy.length > 1) {
                    this.$store.dispatch('snackbar/success', this.$t('common.multisort'))
                }
            }
        },
        search: {
            handler(newValue) {
                this.options.page = 1;
                this.fetchUsers();
                /* if (!this.debouncedFetchItems) {
                    this.debouncedFetchItems = _.debounce(this.fetchUsers, 1000)
                }
                this.debouncedFetchItems() */
            }
        },

        dialog(val) {
            val || this.close()
        }
    },
    computed: {
        ...mapGetters('permissions', ['getRoles'])
    },

    // Fetches posts when the component is created.
    created() {
        /* console.log('dateHelper.getDateTimeFormat');
        console.log(this.dateHelper.getDateTimeTableFormat('2021-05-25T08:41:12+00:00')); */
        // this.fetchUsers();
        /* axios
        .get("/roles")
        .then((response) => {
            this.setRole(response.data);
        })
        .catch((error) => {
            // window.console.log(error);
        }); */
    },

    mounted() {
        /* if (!this.role || this.role != 2) {
        // 2 Admin  4 Agent
        this.$router.push({
            path: "/"
        });
    } */
    },
    // permissions/
    methods: {
        async seletedFileToImport(event) {
            if (event.target.files && event.target.files.length) {
                this.$store.dispatch('loading/start');

                const file = event.target.files[0];
                // console.log(file);

                // this.$store.dispatch('snackbar/success', this.$t('admin.users.import'))

                var formData = new FormData();
                formData.append("file", file);
                /* const res = */ await axios.post('usersFileImport', formData, {
                    timeout: 300000,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                /* console.log('******* res.data ********');
                console.log(res.data); */

                this.fetchUsers();

                this.$store.dispatch('loading/end');
            }
        },
        async importExcel() {
            const fileInputElement = this.$refs.file;
            fileInputElement.click();
        },
        async exportTo() {
            try {
                const res = await axios
                    .get('usersFileExport', { responseType: 'arraybuffer' });

                const blob = new Blob([res.data], { type: 'application/*' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Usuarios Excel.xlsx'
                link._target = 'blank'
                link.click();
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        fetchUsers: debounce(function (e) {
            this.fetchUsersFinal(e);
        }, 500),

        async fetchUsersFinal(data = this.options) {
            /* console.log('********** data ******')
            console.log(data) */
            this.$store.dispatch('loading/start');

            const optionalsParams = []
            if (this.search) { optionalsParams['filter[search]'] = this.search }

            if (data?.sortBy?.length) {
                const joinSort = data.sortBy.map((value, index) =>
                    (data.sortDesc[index] ? '-' : "") + value.replace('attributes.', "")
                );

                optionalsParams.sort = joinSort.join(',')
            }

            try {
                const users = await axios
                    .get(this.urlMain + '?include=roles', {
                        params: {
                            'page[size]': data.itemsPerPage,
                            'page[number]': data.page,
                            ...optionalsParams
                        }
                    })
                /* console.log('*********** users **********')
                console.log(users) */
                this.initialize(users.data);

                this.$store.dispatch('loading/end');
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize($data) {
            this.items = $data.data

            this.pagination.totalItems = $data?.meta?.page?.total ?? 0
        },
        createUser() {
            this.dialog = true;
        },
        /* setRole($data) {
            this.itemsRoles = $data;
        }, */
        editItem(item) {
            this.itemSelected = item;
            this.dialog = true;
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend);

                if (result.status === 204) {
                    this.fetchUsers();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            }
            this.$store.dispatch('loading/end')
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },
        async resetPasswordItem(item) {
            const email = item.attributes.email;
            const id = item.id;
            await this.$refs.changePass.open( {id, email} )
        },
        async showDialogPermissions(item) {
            this.itemSelected = item;
            this.dialogPermissions = true;
        },
        async showDialogGroups(item) {
            try {
                const groups = await axios
                    .get('/groupsByUserId/' + item.id)

                const data = groups.data;

                this.groupsSelected = data.map(e => e + "");

                /* console.log('groupsByUserId')
                console.log(this.groupsSelected) */
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }

            this.itemSelected = item;
            this.dialogGroups = true;
        },
        async changeGroupsSelected(groupsSelected) {
            this.groupsSelected = groupsSelected;

            // for send the groups in JSON API standarization
            const groups = this.groupsSelected;

            var dataSubmit = {
                data: groups.map(r => { return { id: r } })
            }

            try {
                /* const result = */ await axios({
                    method: 'post',
                    url: '/updateGroupsForUser/' + this.itemSelected.id,
                    data: dataSubmit
                });
            } catch (error) {
                if (error?.response?.status === 406) {
                    this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
                }
            }
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.itemSelected = null
            })
        }
    }
}
</script>
